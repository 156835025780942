<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Item
            bladeName="supplier-price-tier"
            :loadingMsg="loadingMsg"
            navigation="supplier-price-tiers"
            :onGetSave="trimItemForSave"
            :onNew="getNewTier"
            :onPullSuccessAsync="pullItem"
            @edit="editItem"
            title="Supplier Price Tier"
            :bladesData="bladesData">
            <template v-slot="{ item, data }">

                <BT-Field-String
                    v-model="item.priceTierName"
                    label="Price Tier Name"
                    :isEditing="data.isEditing || data.isNew" />

                <v-row v-if="!data.isNew" class="my-3">
                    <v-spacer />
                    <BT-Sidebar-List
                        filterProp="PriceTierID"
                        :itemProperties="['ID','Buyer','PriceTierID']"
                        label="Customers"
                        leftIcon="mdi-account-multiple"
                        manyNavigation="customers"
                        manyText="buyer.companyName"
                        :searchProps="['buyer.companyName']"
                        :singleID="item.id"
                        singleProp="priceTierID" />
                </v-row>
                
                <div v-for="(item, index) in item.priceTierItems" :key="index">
                    <Price-Tier-Item-Blade
                        :isEditing="data.isEditing || data.isNew"
                        :item="item">
                        <template v-slot="{ item }">
                            {{ item.product.productName }}
                        </template>
                    </Price-Tier-Item-Blade>
                    <v-divider class="my-1" />
                </div>
                
                <BT-Snack v-model="msg" />

            </template>
        </BT-Blade-Item>
    </div>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Supplier-Price-Tier-Blade',
    components: {
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue'),
        PriceTierItemBlade: () => import('~home/price-tiers/Price-Tier-Item-Blade.vue')
    },
    data: function() {
        return {
            loadingMsg: null,
            msg: null,
            panelV: null,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    computed: {
        isSetup() {
            return this.$route.query && this.$route.query.setup != null;
        }
    },
    methods: {
        async editItem(bladeData, item) {
            try {
                this.loadingMsg = 'Preparing Pricing Tier';
                
                var list = [];
                var products = await this.$BlitzIt.store.getAll('products', { filterBy: 'Sold', properties: 'ID,SortNumber,ProductName,Abbreviation' });

                products.forEach(product => {
                    //try find existing tier item
                    var tierItem = {
                        productID: product.id,
                        product: product,
                        rateFactor: 'unit',
                        valuePerRateFactor: 0,
                        valueIncrements: [],
                        id: '',
                    }

                    var existingTierItem = item.priceTierItems.find(x => x.productID == product.id);
                    if (existingTierItem != null) {
                        tierItem.valuePerRateFactor = existingTierItem.valuePerRateFactor;
                        tierItem.id = existingTierItem.id;

                        if (this.isLengthyArray(existingTierItem.valueIncrements)) {
                            existingTierItem.valueIncrements.forEach(vInc => {
                                tierItem.valueIncrements.push({
                                    id: vInc.id,
                                    unitCountTrigger: vInc.unitCountTrigger,
                                    unitValue: vInc.unitValue
                                })
                            })
                        }
                    }

                    list.push(tierItem);
                })

                item.priceTierItems.forEach(x => {
                    var pItem = list.find(l => l.id == x.id);
                    if (pItem == null) {
                        list.push(x);
                    }
                })

                list.sort(firstBy(x => x.product.sortNumber));

                item.priceTierItems = list;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async getNewTier() {
            //() => { return { priceTierName: null, daysToPay: 0, endOfMonth: false, priceTierItems: [] } }
            var tier = {
                priceTierName: null,
                priceTierItems: []
            };

            await this.editItem(null, tier);

            return tier;
        },
        pullItem(item) {
            item.priceTierItems.sort(firstBy(x => x.product.sortNumber));
            return item;
        },
        trimItemForSave(bladeData, item) {
            item.priceTierItems = item.priceTierItems.filter(x => x.valuePerRateFactor > 0 || (this.isLengthyArray(x.valueIncrements)));
            return item;
        }
    }
}
</script>